$(function () {
  // top to button
  $('a[href^="#"]').on('click', smoothScroll);

  // top to button toggle
  $window.on('scroll resize', pageTopToggle);

  // fixed header
  $window.on('scroll', headerToggle);

  // search
  productSearchSelect();

  var scrollTop = 0
  var $body = $('body')
  var openClass = 'menu-open'
  $('#menuButton').on('click', function () {
    if ($body.hasClass(openClass)) {
      $body.removeClass(openClass)
      $('#siteContentWrapper').removeAttr('style');
      $('html,body').scrollTop(scrollTop);
    } else {
      $body.addClass(openClass)
      scrollTop = $(window).scrollTop()
      $('#siteContentWrapper').css({
        'position': 'fixed',
        'top': -scrollTop
      });
    }
  })

  // table hover
  // !!! OFF 18.12.19
  // $('.table-hover td').hover(function () {
  //   $el = $(this);
  //   maxCol = colCnt($el.closest('table').find('tr:first'));
  //   if (colCnt($el.parent()) < maxCol) {
  //     markUp($el.parent().prevAll('tr:has(td[rowspan]):first'), maxCol - colCnt($el.parent()));
  //   }
  // }, function () {
  //   if ($el !== undefined) {
  //     $el.parent().prevAll('tr:has(td[rowspan])').find('td[rowspan]').removeClass("hover");
  //   }
  // });
});

var $window = $(window);
var $siteHeader = $('#siteHeader');
var headerHeight = $siteHeader.innerHeight();

var $el;
var maxCol;

function colCnt(elem) {
  var colCount = 0;
  $(elem.children()).each(function () {
    if ($(this).attr('colspan')) {
      colCount += +$(this).attr('colspan');
    } else {
      colCount++;
    }
  });
  return colCount;
};

function markUp(elem, n) {
  maxCol = colCnt(elem.closest('table').find('tr:first'));
  elem.find('td[rowspan]').slice(0, n).addClass("hover");
  if (colCnt(elem) < maxCol) {
    markUp(elem.prevAll('tr:has(td[rowspan]):first'), maxCol - colCnt(elem));
  }
}

function headerToggle() {
  $siteHeader.css("left", -$window.scrollLeft());
}

function smoothScroll($target) {
  var speed = 500;
  if ($target.length === undefined) {
    var href = $(this).attr("href");
    var $target = $(href == '#' || href == '' ? 'html' : href);
  }

  $('html, body').animate({
      scrollTop: $target.offset().top - headerHeight
    },
    speed,
    'swing'
  );
}

function pageTopToggle() {
  var $target = $('#topToBtn');

  var now = $window.scrollTop();
  var top = $('html').offset().top + 80;
  var windowWidth = $window.width();

  if (top < now) {
    $target.addClass('visible');
  } else {
    $target.removeClass('visible');
  }

  var over = $target.outerWidth() * 2 + 1040;

  if (over <= windowWidth) {
    var right = (windowWidth - over) / 2;
    $target.css('right', right);
  } else {
    $target.css('right', 0);
  }
}

function productSearchSelect() {
  var $sub = $('#product_kind');
  var subOriginal = $sub.html();

  $('#product_category').change(function () {
    var val1 = $(this).val();
    $sub.html(subOriginal).find('optgroup, .msg').each(function () {
      var val2 = $(this).data('val');
      if (val1 !== '' && val1 !== val2) {
        $(this).remove();
      }
    });
    if ($(this).val() === '') {
      $sub.attr('disabled', 'disabled');
    } else {
      $sub.removeAttr('disabled');
    }
  });
}


if (!Object.assign) {
  Object.defineProperty(Object, 'assign', {
    enumerable: false,
    configurable: true,
    writable: true,
    value: function (target) {
      'use strict';
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object');
      }

      var to = Object(target);
      for (var i = 1; i < arguments.length; i++) {
        var nextSource = arguments[i];
        if (nextSource === undefined || nextSource === null) {
          continue;
        }
        nextSource = Object(nextSource);

        var keysArray = Object.keys(Object(nextSource));
        for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
          var nextKey = keysArray[nextIndex];
          var desc = Object.getOwnPropertyDescriptor(nextSource, nextKey);
          if (desc !== undefined && desc.enumerable) {
            to[nextKey] = nextSource[nextKey];
          }
        }
      }
      return to;
    }
  });
} // Object.assign polyfill

var dtpOptions = {
  yearSuffix: '年',
  dayViewHeaderFormat: 'YYYY{0} MMMM',
  tooltips: {
    close: '閉じる',
    selectMonth: '月を選択',
    prevMonth: '前月',
    nextMonth: '次月',
    selectYear: '年を選択',
    prevYear: '前年',
    nextYear: '次年',
    selectTime: '時間を選択',
    selectDate: '日付を選択',
    prevDecade: '前期間',
    nextDecade: '次期間',
    selectDecade: '期間を選択',
    prevCentury: '前世紀',
    nextCentury: '次世紀'
  },
  icons: {
    time: 'dtp-icon icon-schedule',
    date: 'dtp-icon icon-date_range',
    up: 'dtp-icon icon-arrow_upward',
    down: 'dtp-icon icon-arrow_downward',
    previous: 'dtp-icon icon-navigate_before',
    next: 'dtp-icon icon-navigate_next',
    today: 'dtp-icon icon-network_cell',
    clear: 'dtp-icon icon-trash-2',
    close: 'dtp-icon icon-close'
  },
  locale: 'ja',
  buttons: {
    showClose: true
  }
}

var $window = $(window);

var dtpData = {
  '#DTPPublicDate': {
    format: 'YYYY/MM/DD',
  },
  '#DTPPublicTime': {
    format: 'HH:mm',
  },
  '#DTPFromDate': {
    format: 'YYYY/MM/DD',
  },
  '#DTPFromTime': {
    format: 'HH:mm',
  },
  '#DTPToDate': {
    format: 'YYYY/MM/DD',
  },
  '#DTPToTime': {
    format: 'HH:mm',
  },
};// datetimepicker data


$(function () {
  for (var key in dtpData) {
    console.log('aaa');
    $(key).datetimepicker(Object.assign(dtpOptions, dtpData[key])); // datetimepicker
  }
});
